import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Actions } from '../../../store/actions'
import { withRouter } from 'react-router-dom'
import { Button, ButtonGroup, Form, Modal, Alert } from "react-bootstrap";
import { connect } from 'react-redux';

function _NavBar(props) {
    let dispatch = useDispatch()
    let userName = props.currentUser.userName;
    let activeMenuOption = props.menu.activeMenuOption;
    let showNewPwdWindow = props.changePwd.showNewPwdWindow;
    let isSuccessPwdChange = props.changePwd.isSuccessPwdChange;
    let newPwd = props.changePwd.newPwd;
    let newConfirmPwd = props.changePwd.newConfirmPwd;
    let oldPwd = props.changePwd.oldPwd;
    let isAdmin = props.currentUser.isAdmin;

    useEffect(() => {
        async function getUserByToken() {
            var token = window.localStorage.getItem("token");
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token })
            }
            await fetch("/User/GetUserByToken", requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.status === "Success") {
                            if (!result.data.userName) {
                                deleteAllCookies();
                                window.localStorage.removeItem("token");
                                props.history.push("/login");
                            }
                            else {
                                dispatch(Actions.setCurrentUser(result.data));
                            }
                        } else {
                            deleteAllCookies();
                            window.localStorage.removeItem("token");
                            props.history.push("/login");
                        }
                    },
                    (error) => {
                        deleteAllCookies();
                        window.localStorage.removeItem("token");
                        props.history.push("/login");
                    }
                )
        }

        getUserByToken();
    }, [])

    const deleteAllCookies = () => {
        document.cookie.split(';').forEach(cookie => {
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        });
    }

    const goToHome = () => {
        dispatch(Actions.setUserForAction(""))
        dispatch(Actions.setActiveMenuOption("home"));
        props.history.push("/home");
    }

    const goToHistory = () => {
        dispatch(Actions.setActiveMenuOption("history"));
        props.history.push("/history");
    }

    const goToUsers = () => {
        dispatch(Actions.setActiveMenuOption("users"));
        props.history.push("/users");
    }

    const logout = () => {
        var token = window.localStorage.getItem("token");
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token })
        }
        fetch("/Auth/LogOut", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        window.localStorage.removeItem("token");
                        dispatch(Actions.setActiveMenuOption("home"));
                        dispatch(Actions.setUserForAction(""))
                        props.history.push("/login");
                    }
                },
                (error) => {

                }
            );
    }

    const onChangePassword = () => {
        dispatch(Actions.setShowNewPwdWindow(true));
        dispatch(Actions.setUserForAction(""))
    }

    const handleClose = () => {
        dispatch(Actions.setShowNewPwdWindow(false));
    }

    const AlertDismissible = () => {
        if (!isSuccessPwdChange) {
            return (
                <Alert variant="danger" onClose={() => dispatch(Actions.setIsSuccessPwdChange(true))} dismissible>
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    <p>
                        Enter correct password.
                    </p>
                </Alert>
            );
        } else {
            return null;
        }
    }

    const handleChange = (e) => {
        if (e.currentTarget.id === "newPwd") {
            dispatch(Actions.setNewPwd(e.currentTarget.value));
        } else if (e.currentTarget.id === "newConfirmPwd") {
            dispatch(Actions.setNewConfirmPwd(e.currentTarget.value));
        } else if (e.currentTarget.id === "oldPwd") {
            dispatch(Actions.setOldPwd(e.currentTarget.value));
        }
    }

    const onSetNewPassword = () => {
        if (!newPwd || !newConfirmPwd || newPwd !== newConfirmPwd) {
            dispatch(Actions.setIsSuccessPwdChange(false));
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userName, oldPwd, newPwd })
        }
        fetch("/User/ChangePassword", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        dispatch(Actions.setShowNewPwdWindow(false));
                        props.history.push("/login");
                    }
                    else {
                        dispatch(Actions.setIsSuccessPwdChange(false));
                    }
                },
                (error) => {
                    dispatch(Actions.setIsSuccessPwdChange(false));
                }
            )
    }

    return (
        <div className="midleColumnWidth navBarDiv">
            <ButtonGroup vertical className="fullWidth">
                <span className="fullWidth">Logged in as {userName}</span>
                <>
                    {activeMenuOption === "home" && (
                        <>
                            <Button variant="light" className="activeMenuOption menuButton" onClick={goToHome}>Active Files</Button>
                            <Button variant="light" className="menuButton" onClick={goToHistory}>History</Button>
                            {isAdmin && <Button variant="light" className="menuButton" onClick={goToUsers}>Users</Button>}
                        </>
                    )}
                    {activeMenuOption === "history" && (
                        <>
                            <Button variant="light" className="menuButton" onClick={goToHome}>Active Files</Button>
                            <Button variant="light" className="activeMenuOption menuButton" onClick={goToHistory}>History</Button>
                            {isAdmin && <Button variant="light" className="menuButton" onClick={goToUsers}>Users</Button>}
                        </>
                    )}
                    {activeMenuOption === "users" && (
                        <>
                            <Button variant="light" className="menuButton" onClick={goToHome}>Active Files</Button>
                            <Button variant="light" className="menuButton" onClick={goToHistory}>History</Button>
                            {isAdmin && <Button variant="light" className="activeMenuOption menuButton" onClick={goToUsers}>Users</Button>}
                        </>
                    )}
                </>
                <div className="fullWidth navbarLinksDiv">
                    <Button variant="link" className="menuButton" onClick={onChangePassword}>Change password</Button>
                    <Button variant="link" className="menuButton" onClick={logout}>Logout</Button>
                </div>
            </ButtonGroup>

            <Modal show={showNewPwdWindow} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlid="currentPwd">
                            <Form.Label>Current Password</Form.Label>
                            <Form.Control type="password" placeholder="Current Password" onChange={handleChange} controlid="oldPwd" id="oldPwd" />
                        </Form.Group>
                        <Form.Group controlid="editedPwd">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="password" value={newPwd} placeholder="New Password" onChange={handleChange} controlid="newPwd" id="newPwd" />
                        </Form.Group>
                        <Form.Group controlid="editedConfirmPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control type="password" value={newConfirmPwd} placeholder="Confirm Password" onChange={handleChange} controlid="newConfirmPwd" id="newConfirmPwd" />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="secondary" onClick={onSetNewPassword}>
                        Save Changes
                    </Button>
                </Modal.Footer>
                <AlertDismissible />
            </Modal>
        </div>
    )
}

const NavBar = connect(state => ({
    ...state
}))(_NavBar);

export default withRouter(NavBar)