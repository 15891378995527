import { Actions } from '../constants/actionsConstants'

const initialState = {
    currentUser: {
        userName: "",
        pwd: "",
        isAdmin: false
    },
    changePwd: {
        newPwd: "",
        newConfirmPwd: "",
        oldPwd: "",
        showNewPwdWindow: false,
        isSuccessPwdChange: true
    },
    auth: {
        isSuccessAuth: true,
        authUserName: "",
        authPwd: "",
        authConfirmPwd: "",
    },
    createUser: {
        showCreateWindow: false,
        newUser: {
            userName: "",
            pwd: "",
            isAdmin: false
        },
        isSuccessCreation: true
    },
    editUser: {
        selectedUser: {
            userName: "",
            pwd: "",
            isAdmin: ""
        },
        newUser: {
            userName: "",
            pwd: "",
            isAdmin: false
        },
        showEditWindow: false,
        isSuccessEdit: true,
    },
    actions: {
        action: null,
        param: null
    },
    folder: {
        folders: [],
        shownFolders: [],
        foldersNames: []
    },
    createFolder: {
        newFolder: "",
        parentFolder: ""
    },
    uploadFile: {
        parentFolder: "",
        file: {
            file: {},
            base64: ""
        },
        fileReceiverEmail: ""
    },
    users: [],
    link: {
        selectedLink: ""
    },
    menu: {
        activeMenuOption: "home"
    },
    pagination: {
        activePageNumber: 1,
        pages: []
    },
    homeComponent: {
        showCreateFolderErrorWindow: false,
        showUploadFileErrorWindow: false,
        showGetFileLinkErrorWindow: false,
        showRemoveFolderErrorWindow: false,
        showRemoveFileErrorWindow: false,
        showUpdateFolderNameErrorWindow: false,
        errorWindowMessage: "",
        showHomeSuccessWindow: false,
        fileNameSortType: "asc",
        recipientSortType: "asc",
        dateSentSortType: "asc",
        uploadingStatus: 0,
        showUploadProgress: false
    },
    controlPanelComponent: {
        showRemoveUserErrorWindow: false,
        showReactivateLinkErrorWindow: false
    },
    isLoading: true,
    isAuthLoading: false,
    userForAction: "",
    showActionErrorWindow: false,
    changeFolderName: {
        updatedFolder: {
            folderId: "",
            folderName: ""
        },
        showNewFolderNameWindow: false
    },
    receivers: {
        showReceiversWindow: false,
        showReceiversErrorWindow: false,
        receivers: [
            {
                id: 1,
                email: ""
            }
        ],
        fileId: ""
    },
    sorting: {
        type: "asc"
    },
    usersComponent: {
        userNameSortType: "asc",
        userTyprSortType: "asc",
        sortField: 0
    },
    historyComponent: {
        userNameSortType: "asc",
        fileNameSortType: "asc",
        recipientSortType: "asc",
        dateSentSortType: "asc",
        selectedFilterType: "Contains",
        filterCriteriaValue: "",
        selectedSortField: 0,
        selectedSortType: "asc",
        selectedFilterField: 0
    }
}

export const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SET_CURRENT_USER: {
            return { ...state, currentUser: action.payload }
        }
        case Actions.SET_IS_SUCCESS_AUTH: {
            return { ...state, auth: { ...state.auth, isSuccessAuth: action.payload } }
        }
        case Actions.SET_AUTH_USERNAME: {
            return { ...state, auth: { ...state.auth, authUserName: action.payload } }
        }
        case Actions.SET_AUTH_PWD: {
            return { ...state, auth: { ...state.auth, authPwd: action.payload } }
        }
        case Actions.SET_AUTH_CONFIRM_PWD: {
            return { ...state, auth: { ...state.auth, authConfirmPwd: action.payload } }
        }
        case Actions.SET_SHOW_NEW_PWD_WINDOW: {
            return { ...state, changePwd: { ...state.changePwd, showNewPwdWindow: action.payload } }
        }
        case Actions.SET_IS_SUCCESS_PWD_CHANGE: {
            return { ...state, changePwd: { ...state.changePwd, isSuccessPwdChange: action.payload } }
        }
        case Actions.SET_NEW_PWD: {
            return { ...state, changePwd: { ...state.changePwd, newPwd: action.payload } }
        }
        case Actions.SET_NEW_CONFIRM_PWD: {
            return { ...state, changePwd: { ...state.changePwd, newConfirmPwd: action.payload } }
        }
        case Actions.SET_OLD_PWD: {
            return { ...state, changePwd: { ...state.changePwd, oldPwd: action.payload } }
        }
        case Actions.SET_ACTIVE_MENU_OPTION: {
            return { ...state, menu: { ...state.menu, activeMenuOption: action.payload } }
        }
        case Actions.SET_FOLDERS: {
            return { ...state, folder: { ...state.folder, folders: action.payload } }
        }
        case Actions.SET_FOLDERS_NAMES: {
            return { ...state, folder: { ...state.folder, foldersNames: action.payload } }
        }
        case Actions.SET_CREATION_PARENT_FOLDER_NAME: {
            return { ...state, createFolder: { ...state.createFolder, parentFolder: action.payload } }
        }
        case Actions.SET_UPLOAD_PARENT_FOLDER_NAME: {
            return { ...state, uploadFile: { ...state.uploadFile, parentFolder: action.payload } }
        }
        case Actions.SET_UPLOAD_FILE: {
            return { ...state, uploadFile: { ...state.uploadFile, file: action.payload } }
        }
        case Actions.SET_NEW_FOLDER_NAME: {
            return { ...state, createFolder: { ...state.createFolder, newFolder: action.payload } }
        }
        case Actions.SET_SHOWN_FOLDERS: {
            return { ...state, folder: { ...state.folder, shownFolders: action.payload } }
        }
        case Actions.SET_ACTION: {
            return { ...state, actions: { ...state.actions, action: action.payload } }
        }
        case Actions.SET_PARAM: {
            return { ...state, actions: { ...state.actions, param: action.payload } }
        }
        case Actions.SET_USERS: {
            return { ...state, users: action.payload }
        }
        case Actions.SET_SHOW_EDIT_WINDOW: {
            return { ...state, editUser: { ...state.editUser, showEditWindow: action.payload } }
        }
        case Actions.SET_SELECTED_USER: {
            return { ...state, editUser: { ...state.editUser, selectedUser: action.payload } }
        }
        case Actions.SET_EDIT_USER_NAME: {
            return { ...state, editUser: { ...state.editUser, newUser: { ...state.editUser.newUser, userName: action.payload } } }
        }
        case Actions.SET_EDIT_PWD: {
            return { ...state, editUser: { ...state.editUser, newUser: { ...state.editUser.newUser, pwd: action.payload } } }
        }
        case Actions.SET_EDIT_IS_ADMIN: {
            return { ...state, editUser: { ...state.editUser, newUser: { ...state.editUser.newUser, isAdmin: action.payload } } }
        }
        case Actions.SET_SELECTED_FOR_EDIT_USER: {
            return { ...state, editUser: { ...state.editUser, newUser: action.payload } }
        }
        case Actions.SET_IS_SUCCESS_EDIT: {
            return { ...state, editUser: { ...state.editUser, isSuccessEdit: action.payload } }
        }
        case Actions.SET_SELECTED_LINK: {
            return { ...state, selectedLink: action.payload }
        }
        case Actions.SET_IS_ADMIN: {
            return { ...state, isAdmin: action.payload }
        }
        case Actions.SET_ACTIVE_PAGE_NUMBER: {
            return { ...state, pagination: { ...state.pagination, activePageNumber: action.payload } }
        }
        case Actions.SET_PAGES: {
            return { ...state, pagination: { ...state.pagination, pages: action.payload } }
        }
        case Actions.SET_SHOW_CREATE_FOLDER_ERROR_WINDOW: {
            return { ...state, homeComponent: { ...state.homeComponent, showCreateFolderErrorWindow: action.payload } }
        }
        case Actions.SET_SHOW_UPLOAD_FILE_ERROR_WINDOW: {
            return { ...state, homeComponent: { ...state.homeComponent, showUploadFileErrorWindow: action.payload } }
        }
        case Actions.SET_SHOW_GET_FILE_LINK_ERROR_WINDOW: {
            return { ...state, homeComponent: { ...state.homeComponent, showGetFileLinkErrorWindow: action.payload } }
        }
        case Actions.SET_SHOW_REMOVE_FOLDER_ERROR_WINDOW: {
            return { ...state, homeComponent: { ...state.homeComponent, showRemoveFolderErrorWindow: action.payload } }
        }
        case Actions.SET_SHOW_REMOVE_FILE_ERROR_WINDOW: {
            return { ...state, homeComponent: { ...state.homeComponent, showRemoveFileErrorWindow: action.payload } }
        }
        case Actions.SET_SHOW_REMOVE_USER_ERROR_WINDOW: {
            return { ...state, controlPanelComponent: { ...state.controlPanelComponent, showRemoveUserErrorWindow: action.payload } }
        }
        case Actions.SET_SHOW_REACTIVATE_LINK_ERROR_WINDOW: {
            return { ...state, controlPanelComponent: { ...state.controlPanelComponent, showReactivateLinkErrorWindow: action.payload } }
        }
        case Actions.SET_HOME_COMPONENT_ERROR_WINDOW_MESSAGE: {
            return { ...state, homeComponent: { ...state.homeComponent, errorWindowMessage: action.payload } }
        }
        case Actions.SET_IS_LOADING: {
            return { ...state, isLoading: action.payload }
        }
        case Actions.SET_IS_AUTH_LOADING: {
            return { ...state, isAuthLoading: action.payload }
        }
        case Actions.SET_UPLOAD_FILE_RECEIVER_EMAIL: {
            return { ...state, uploadFile: { ...state.uploadFile, fileReceiverEmail: action.payload } }
        }
        case Actions.SET_SHOW_CREATE_WINDOW: {
            return { ...state, createUser: { ...state.createUser, showCreateWindow: action.payload } }
        }
        case Actions.SET_IS_SUCCESS_CREATION: {
            return { ...state, createUser: { ...state.createUser, isSuccessCreation: action.payload } }
        }
        case Actions.SET_CREATION_IS_ADMIN: {
            return { ...state, createUser: { ...state.createUser, newUser: { ...state.createUser.newUser, isAdmin: action.payload } } }
        }
        case Actions.SET_CREATION_PWD: {
            return { ...state, createUser: { ...state.createUser, newUser: { ...state.createUser.newUser, pwd: action.payload } } }
        }
        case Actions.SET_CREATION_USER_NAME: {
            return { ...state, createUser: { ...state.createUser, newUser: { ...state.createUser.newUser, userName: action.payload } } }
        }
        case Actions.SET_USER_FOR_ACTION: {
            return { ...state, userForAction: action.payload }
        }
        case Actions.SET_SHOW_ACTION_ERROR_WINDOW: {
            return { ...state, showActionErrorWindow: action.payload }
        }
        case Actions.SET_SHOW_NEW_FOLDER_NAME_WINDOW: {
            return { ...state, changeFolderName: { ...state.changeFolderName, showNewFolderNameWindow: action.payload } }
        }
        case Actions.SET_UPDATED_FOLDER_ID: {
            return {
                ...state, changeFolderName: { ...state.changeFolderName, updatedFolder: { ...state.changeFolderName.updatedFolder, folderId: action.payload } }
            }
        }
        case Actions.SET_SHOW_UPDATED_FOLDER_NAME: {
            return {
                ...state, changeFolderName: { ...state.changeFolderName, updatedFolder: { ...state.changeFolderName.updatedFolder, folderName: action.payload } }
            }
        }
        case Actions.SET_SHOW_UPDATE_FOLDER_NAME_ERROR_WINDOW: {
            return { ...state, homeComponent: { ...state.homeComponent, showUpdateFolderNameErrorWindow: action.payload } }
        }
        case Actions.SET_SHOW_UPDATED_FOLDER_NAME: {
            return {
                ...state, changeFolderName: { ...state.changeFolderName, updatedFolder: action.payload }
            }
        }
        case Actions.SET_SHOW_RECEIVERS_WINDOW: {
            return {
                ...state, receivers: { ...state.receivers, showReceiversWindow: action.payload }
            }
        }
        case Actions.SET_SHOW_RECEIVERS_ERROR_WINDOW: {
            return {
                ...state, receivers: { ...state.receivers, showReceiversErrorWindow: action.payload }
            }
        }
        case Actions.SET_ADD_RECEIVER: {
            var newReceivers = state.receivers.receivers.slice();
            newReceivers.push(action.payload);
            return {
                ...state, receivers: { ...state.receivers, receivers: newReceivers }
            }
        }
        case Actions.SET_EDIT_RECEIVER: {
            return {
                ...state, receivers: { ...state.receivers, receivers: state.receivers.receivers.filter(function (e, i) { if (e.id == action.payload.id) { e.email = action.payload.email; } return e; }) }
            }
        }
        case Actions.SET_EDIT_RECEIVER_FILE_ID: {
            return {
                ...state, receivers: { ...state.receivers, fileId: action.payload }
            }
        }
        case Actions.SET_SHOW_HOME_SUCCESS_WINDOW: {
            return { ...state, homeComponent: { ...state.homeComponent, showHomeSuccessWindow: action.payload } }
        }
        case Actions.SET_CHANGE_SORT_TYPE: {
            return {
                ...state, sorting: { ...state.sorting, type: state.sorting.type == "asc" ? "des" : "asc" }
            }
        }
        case Actions.SET_CHANGE_FILE_NAME_SORT_TYPE: {
            return {
                ...state, homeComponent: {
                    ...state.homeComponent,
                    fileNameSortType: state.homeComponent.fileNameSortType == "asc" ? "des" : "asc",
                    recipientSortType: "asc",
                    dateSentSortType: "asc"
                }
            }
        }
        case Actions.SET_CHANGE_RECIPIENT_SORT_TYPE: {
            return {
                ...state, homeComponent: {
                    ...state.homeComponent,
                    fileNameSortType: "asc",
                    recipientSortType: state.homeComponent.recipientSortType == "asc" ? "des" : "asc",
                    dateSentSortType: "asc"
                }
            }
        }
        case Actions.SET_CHANGE_DATE_SENT_SORT_TYPE: {
            return {
                ...state, homeComponent: {
                    ...state.homeComponent,
                    fileNameSortType: "asc",
                    recipientSortType: "asc",
                    dateSentSortType: state.homeComponent.dateSentSortType == "asc" ? "des" : "asc"
                }
            }
        }
        case Actions.SET_CHANGE_USERS_SORT_FIELD: {
            return {
                ...state, usersComponent: {
                    ...state.usersComponent,
                    sortField: action.payload
                }
            }
        }
        case Actions.SET_CHANGE_USER_NAME_SORT_TYPE: {
            return {
                ...state, usersComponent: {
                    ...state.usersComponent,
                    sortField: 0,
                    userNameSortType: state.usersComponent.userNameSortType == "asc" ? "des" : "asc",
                    userTyprSortType: "asc"
                }
            }
        }
        case Actions.SET_CHANGE_USER_TYPE_SORT_TYPE: {
            return {
                ...state, usersComponent: {
                    ...state.usersComponent,
                    sortField: 1,
                    userNameSortType: "asc",
                    userTyprSortType: state.usersComponent.userTyprSortType == "asc" ? "des" : "asc"
                }
            }
        }
        case Actions.SET_CHANGE_HISTORY_SORT_FIELD_AND_TYPE: {
            return {
                ...state, historyComponent: {
                    ...state.historyComponent,
                    userNameSortType: action.payload.selectedSortField == 0 ? action.payload.selectedSortType : "asc",
                    fileNameSortType: action.payload.selectedSortField == 1 ? action.payload.selectedSortType : "asc",
                    recipientSortType: action.payload.selectedSortField == 2 ? action.payload.selectedSortType : "asc",
                    dateSentSortType: action.payload.selectedSortField == 3 ? action.payload.selectedSortType : "asc"
                }
            }
        }
        case Actions.SET_CHANGE_HISTORY_USER_NAME_FILTER_TYPE: {
            return {
                ...state, historyComponent: {
                    ...state.historyComponent,
                    selectedFilterType: action.payload
                }
            }
        }
        case Actions.SET_CHANGE_HISTORY_FILTER_CRITERIA_VALUE: {
            return {
                ...state, historyComponent: {
                    ...state.historyComponent,
                    filterCriteriaValue: action.payload
                }
            }
        }
        case Actions.SET_CHANGE_HISTORY_SELECTED_SORT_FIELD: {
            return {
                ...state, historyComponent: {
                    ...state.historyComponent,
                    selectedSortField: action.payload
                }
            }
        }
        case Actions.SET_CHANGE_HISTORY_SELECTED_SORT_TYPE: {
            return {
                ...state, historyComponent: {
                    ...state.historyComponent,
                    selectedSortType: action.payload
                }
            }
        }
        case Actions.SET_CHANGE_HISTORY_SELECTED_FILTER_FIELD: {
            return {
                ...state, historyComponent: {
                    ...state.historyComponent,
                    selectedFilterField: action.payload
                }
            }
        }
        case Actions.SET_UPLOAD_PROGRESS_BAR_STAUTS: {
            return {
                ...state, homeComponent: {
                    ...state.homeComponent,
                    uploadingStatus: (action.payload < 0 ? 0 : (action.payload > 100 ? 100 : action.payload))
                }
            }
        }
        case Actions.SET_SHOW_UPLOAD_PROGRESS: {
            return {
                ...state, homeComponent: {
                    ...state.homeComponent,
                    showUploadProgress: action.payload
                }
            }
        }
        default: {
            return state;
        }
    }
}