import React, { useEffect, useState, useRef } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Actions } from '../../store/actions'
import NavBar from '../common/navbar/index'
import { Table, Tooltip, Overlay, Dropdown, Button, ButtonGroup, Modal, Form, Alert, Pagination } from "react-bootstrap";
import { connect } from 'react-redux';
import sortDownImage from '../../assets/img/sort_down.png';
import sortUpImage from '../../assets/img/sort_up.png';
import filterImage from '../../assets/img/filter.png';

function _ControlPanel(props) {
    const WINDOW_WIDTH = document.documentElement.clientWidth;
    const FILE_NAME_LENGTH = WINDOW_WIDTH >= 1100 ? 38 : 13;

    let dispatch = useDispatch();
    let users = props.users;
    let action = props.actions.action;
    let param = props.actions.param;
    let showRemoveUserErrorWindow = props.controlPanelComponent.showRemoveUserErrorWindow;
    let showReactivateLinkErrorWindow = props.controlPanelComponent.showReactivateLinkErrorWindow;
    let isLoading = props.isLoading;
    let showActionErrorWindow = props.showActionErrorWindow;
    let currentUser = props.currentUser;

    let selectedSortField = props.historyComponent.selectedSortField;
    let selectedSortType = props.historyComponent.selectedSortType;
    let userNameSortType = props.historyComponent.userNameSortType;
    let fileNameSortType = props.historyComponent.fileNameSortType;
    let recipientSortType = props.historyComponent.recipientSortType;
    let dateSentSortType = props.historyComponent.dateSentSortType;

    let selectedFilterType = props.historyComponent.selectedFilterType;
    let selectedFilterField = props.historyComponent.selectedFilterField;
    let filterCriteriaValue = props.historyComponent.filterCriteriaValue;

    const HISTORY_SORT_FIELDS_ENUM = {
        userName: 0,
        fileName: 1,
        recipient: 2,
        dateSent: 3
    }
    const HISTORY_FILTER_FIELDS_ENUM = {
        none: 0,
        userName: 1,
        fileName: 2,
        emailDomain: 3
    }


    //const handleWindowBeforeUnload = e => {
    //    if (window.performance && performance.navigation.type != 1) {
    //        window.localStorage.removeItem("token");
    //    }
    //};

    //window.addEventListener('beforeunload', handleWindowBeforeUnload);

    const [show, setShow] = useState(false);

    const [showUserNameFilter, setShowUserNameFilter] = useState(false);
    const [showFileNameFilter, setShowFileNameFilter] = useState(false);
    const [showEmailDomainFilter, setShowEmailDomainFilter] = useState(false);
    const userNameTarget = useRef(null);
    const fileNameTarget = useRef(null);
    const emailDomainTarget = useRef(null);

    const handleClose = () => setShow(false);

    const _Loader = () => {
        if (isLoading) {
            return (
                <div className="rowLoader">
                    <div className="columnLoader">
                        <div id="homeLoader" className="spinner-border loader" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="ms-2">
                            Loading...
                        </div>
                    </div>
                </div>
            )
        } else {
            return (<></>)
        }
    }

    const handleAction = () => {
        if (action) {
            action(param);
        }
    };

    const getAllUsers = async (token) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token, sortField: selectedSortField, sortType: selectedSortType, filterField: selectedFilterField, filterType: selectedFilterType, filterCriteria: filterCriteriaValue })
        }
        dispatch(Actions.setIsLoading(true));
        await fetch("/User/GetAllUsers", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        dispatch(Actions.setUsers(result.data));
                        dispatch(Actions.setHistorySortFieldAndType({ selectedSortField, selectedSortType }));
                        dispatch(Actions.setIsLoading(false));
                    } else {
                        dispatch(Actions.setUsers([]));
                        dispatch(Actions.setIsLoading(false));
                    }
                },
                (error) => {
                    dispatch(Actions.setUsers([]));
                    dispatch(Actions.setIsLoading(false));
                }
            )
    }

    useEffect(() => {
        var token = window.localStorage.getItem("token");
        getAllUsers(token);
    }, [])

    const resendNotification = (e) => {
        var token = window.localStorage.getItem("token");
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ linkId: e.currentTarget.id, token })
        }
        dispatch(Actions.setIsLoading(true));
        fetch("/Link/ResendNotification", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success" && !result.message) {
                        getAllUsers(token);
                        dispatch(Actions.setIsLoading(false));
                    } else {
                        dispatch(Actions.setShowReactivateLinkErrorWindow(true));
                        dispatch(Actions.setIsLoading(false));
                    }
                },
                (error) => {
                    dispatch(Actions.setShowReactivateLinkErrorWindow(true));
                    dispatch(Actions.setIsLoading(false));
                }
            )
    }

    const Confirmation = () => {
        return (
            <>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="dark" onClick={handleClose}>
                            No
                        </Button>
                        <Button variant="primary" onClick={handleAction}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    const AlertDismissible = (params) => {
        if (params.show) {
            return (
                <Alert variant="danger" onClose={() => dispatch(params.action(!params.show))} dismissible>
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    <p>
                        {params.message}
                    </p>
                </Alert>
            );
        } else {
            return null;
        }
    }

    const goByLink = function (e) {
        window.open(e.currentTarget.id + "local");
    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const usersTableBody = users.map((e, i) => {
        return (
            currentUser.userName === e.userName || currentUser.isAdmin ?
                <>
                    <tr className="rowWidth" key={i}>
                        {currentUser.isAdmin ? <td className="midleColumnWidth" rowSpan={e.links.length || 1}><span>{e.userName}</span></td> : <></>}
                        {
                            <>
                                {(e.links.length && !e.links[0].isActive) ?
                                    <>
                                        <td className="bigColumnWidth" title={e.links[0].fileName}><span>{e.links[0].fileName.length > FILE_NAME_LENGTH ? e.links[0].fileName.slice(0, FILE_NAME_LENGTH + 1) + "..." : e.links[0].fileName}</span></td>
                                        <td className="bigColumnWidth">{e.links[0].recipient}</td>
                                        <td className="middleColumnWidth">{formatDate(new Date(e.links[0].dateOfCreation).toDateString())}</td>
                                        <td className="smallColumnWidth">
                                        </td>
                                        <td className="smallColumnWidth">{e.links[0].downloadsCount}</td>
                                        {currentUser.isAdmin ? <td className="smallColumnWidth">{e.links[0].daysLeftBeforeDeactivation}</td> : <></>}
                                    </>
                                    : (
                                        e.links.length ?
                                            <>
                                                <td className="bigColumnWidth" title={e.links[0].fileName}><span>{e.links[0].fileName.length > FILE_NAME_LENGTH ? e.links[0].fileName.slice(0, FILE_NAME_LENGTH + 1) + "..." : e.links[0].fileName}</span></td>
                                                <td className="bigColumnWidth">{e.links[0].recipient}</td>
                                                <td className="middleColumnWidth">{formatDate(new Date(e.links[0].dateOfCreation).toDateString())}</td>
                                                <td className="smallColumnWidth">
                                                    <Button id={e.links[0].url} variant="link" onClick={goByLink}>link</Button>
                                                </td>
                                                <td className="smallColumnWidth">{e.links[0].downloadsCount}</td>
                                                {currentUser.isAdmin ? <td className="smallColumnWidth">{e.links[0].daysLeftBeforeDeactivation}</td> : <></>}
                                            </>
                                            : <>
                                                <td className="bigColumnWidth"></td>
                                                <td className="bigColumnWidth"></td>
                                                <td className="middleColumnWidth"></td>
                                                <td className="smallColumnWidth"></td>
                                                <td className="smallColumnWidth"></td>
                                                {currentUser.isAdmin ? <td className="smallColumnWidth"></td> : <></>}
                                            </>
                                    )
                                }
                            </>
                        }
                    </tr>
                    {
                        e.links.length ? e.links.slice(1).map((e, i) => {
                            return (
                                <>
                                    <tr className="rowWidth" key={i}>
                                        {(!e.isActive) ? (
                                            <>
                                                <td className="bigColumnWidth" title={e.fileName}>{e.fileName.length > FILE_NAME_LENGTH ? e.fileName.slice(0, FILE_NAME_LENGTH + 1) + "..." : e.fileName}</td>
                                                <td className="bigColumnWidth">{e.recipient}</td>
                                                <td className="middleColumnWidth">{formatDate(new Date(e.dateOfCreation).toDateString())}</td>
                                                <td className="smallColumnWidth"></td>
                                                <td className="smallColumnWidth">{e.downloadsCount}</td>
                                                {currentUser.isAdmin ? <td className="smallColumnWidth">{e.daysLeftBeforeDeactivation}</td> : <></>}
                                            </>

                                        )
                                            : <>
                                                <td className="bigColumnWidth" title={e.fileName}>
                                                    {
                                                        e.fileName.length > FILE_NAME_LENGTH ?
                                                            e.fileName.slice(0, FILE_NAME_LENGTH + 1) +
                                                            "..." : e.fileName
                                                    }
                                                </td>
                                                <td className="bigColumnWidth">{e.recipient}</td>
                                                <td className="middleColumnWidth">{formatDate(new Date(e.dateOfCreation).toDateString())}</td>
                                                <td className="smallColumnWidth">
                                                    <Button id={e.url} variant="link" onClick={goByLink}>link</Button>
                                                </td>
                                                <td className="smallColumnWidth">{e.downloadsCount}</td>
                                                {currentUser.isAdmin ? <td className="smallColumnWidth">{e.daysLeftBeforeDeactivation}</td> : <></>}
                                            </>
                                        }
                                    </tr>
                                </>
                            )
                        }) : <></>
                    }
                </> : <></>
        )
    })

    //sorting
    const sort = (sortField) => {
        var sortType;
        switch (sortField) {
            default:
            case HISTORY_SORT_FIELDS_ENUM.userName: {
                sortType = userNameSortType == "asc" ? "des" : "asc";
                break;
            }
            case HISTORY_SORT_FIELDS_ENUM.fileName: {
                sortType = fileNameSortType == "asc" ? "des" : "asc";
                break;
            }
            case HISTORY_SORT_FIELDS_ENUM.recipient: {
                sortType = recipientSortType == "asc" ? "des" : "asc";
                break;
            }
            case HISTORY_SORT_FIELDS_ENUM.dateSent: {
                sortType = dateSentSortType == "asc" ? "des" : "asc";
            }
        }

        dispatch(Actions.setHistorySelectedSortField(sortField));
        dispatch(Actions.setHistorySelectedSortType(sortType));
        selectedSortField = sortField;
        selectedSortType = sortType;

        var token = window.localStorage.getItem("token");
        getAllUsers(token);
    }

    const sortByUserName = () => {
        sort(HISTORY_SORT_FIELDS_ENUM.userName);
    }

    const sortByDate = () => {
        sort(HISTORY_SORT_FIELDS_ENUM.dateSent);
    }

    const sortByFileName = () => {
        sort(HISTORY_SORT_FIELDS_ENUM.fileName);
    }

    const sortByRecipient = () => {
        sort(HISTORY_SORT_FIELDS_ENUM.recipient);
    }
    //


    //filtering
    const openFilterByUserName = () => {
        setShowUserNameFilter(!showUserNameFilter);

        //if (selectedFilterField == 1) {
        //    dispatch(Actions.setHistorySelectedFilterField(HISTORY_FILTER_FIELDS_ENUM.none));
        //    selectedFilterField = 0;
        //}
    }

    const openFilterByFileName = () => {
        setShowFileNameFilter(!showFileNameFilter);

        //if (selectedFilterField == 2) {
        //    dispatch(Actions.setHistorySelectedFilterField(HISTORY_FILTER_FIELDS_ENUM.none));
        //    selectedFilterField = 0;
        //}
    }

    const openFilterByEmailDomain = () => {
        setShowEmailDomainFilter(!showEmailDomainFilter);

        //if (selectedFilterField == 3) {
        //    dispatch(Actions.setHistorySelectedFilterField(HISTORY_FILTER_FIELDS_ENUM.none));
        //    selectedFilterField = 0;
        //}
    }

    const onChangeFilterType = (e) => {
        var filterType = e.currentTarget.id;
        dispatch(Actions.setHistoryselectedFilterType(filterType));
        selectedFilterType = filterType;
    }

    const onChangeFilterCriteriaValue = () => {
        var value = document.getElementById("filterCriteriaValue").value;
        dispatch(Actions.setHistoryFilterCriteriaValue(value));
    }

    const filter = () => {
        var token = window.localStorage.getItem("token");
        getAllUsers(token);
    }

    const filterByUserName = () => {
        dispatch(Actions.setHistorySelectedFilterField(HISTORY_FILTER_FIELDS_ENUM.userName));
        selectedFilterField = 1;
        filter();
    }

    const filterByFileName = () => {
        dispatch(Actions.setHistorySelectedFilterField(HISTORY_FILTER_FIELDS_ENUM.fileName));
        selectedFilterField = 2;
        filter();
    }

    const filterByRecipient = () => {
        dispatch(Actions.setHistorySelectedFilterField(HISTORY_FILTER_FIELDS_ENUM.emailDomain));
        selectedFilterField = 3;
        filter();
    }

    const onClearFilterCriteriaValue = () => {
        dispatch(Actions.setHistoryFilterCriteriaValue(""));
    }

    const overlay = (target, handler, showState, openCloseHandler) => {
        return (
            <Overlay target={target.current} show={showState} placement="bottom">
                {
                    <Tooltip>
                        <Dropdown className="toolTipMargin">
                            <Dropdown.Toggle className="toolTipElementSize" variant="light" id="dropdownValue">
                                {selectedFilterType}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item id="Contains" key="containsOption" onClick={onChangeFilterType}>Contains</Dropdown.Item>
                                <Dropdown.Item id="Equals" key="equalsOption" onClick={onChangeFilterType}>Equals</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className="flexColumn">
                            <input className="toolTipElementSize"
                                id="filterCriteriaValue"
                                maxLength="20"
                                value={filterCriteriaValue}
                                onChange={onChangeFilterCriteriaValue}>
                            </input>
                            <div className="tooltipButtonsDiv">
                                <Button variant="light" onClick={handler}>Apply</Button>
                                <Button variant="light" onClick={onClearFilterCriteriaValue}>Remove</Button>
                                <Button variant="light" onClick={openCloseHandler}>Close</Button>
                            </div>
                        </div>
                    </Tooltip>
                }
            </Overlay>
        )
    }
    //

    if (isLoading) {
        return <_Loader />
    } else {
        if (users.length || window.localStorage.getItem("token")) {
            return (
                <div className="homeDiv">
                    <NavBar></NavBar>
                    <div className="homeMainDiv">
                        <AlertDismissible message="Error! User wasn't removed." show={showRemoveUserErrorWindow} action={Actions.setShowRemoveUserErrorWindow} />
                        <AlertDismissible message="Notification wasn't send." show={showReactivateLinkErrorWindow} action={Actions.setShowReactivateLinkErrorWindow} />
                        <AlertDismissible message="Select user before action." show={showActionErrorWindow} action={Actions.setShowActionErrorWindow} />
                        <Confirmation />
                        <Table striped bordered hover className="rowWidth mediumBottomMargin">
                            <thead className="rowWidth">
                                <tr className="rowWidth">
                                    {
                                        currentUser.isAdmin ?
                                            <th className="midleColumnWidth">
                                                User Name
                                                <img ref={userNameTarget} src={filterImage} className="sortImage filterImage"
                                                    onClick={openFilterByUserName} />
                                                {overlay(userNameTarget, filterByUserName, showUserNameFilter, openFilterByUserName)}
                                                {userNameSortType == "asc" ?
                                                    <img src={sortUpImage} className="sortImage" onClick={sortByUserName} /> :
                                                    <img src={sortDownImage} className="sortImage" onClick={sortByUserName} />
                                                }
                                            </th> :
                                            <></>
                                    }
                                    <th className="middleColumnWidth">
                                        File Name
                                        <img ref={fileNameTarget} src={filterImage} className="sortImage filterImage"
                                            onClick={openFilterByFileName} />
                                        {overlay(fileNameTarget, filterByFileName, showFileNameFilter, openFilterByFileName)}
                                        {
                                            fileNameSortType == "asc" ?
                                                <img src={sortUpImage} className="sortImage" onClick={sortByFileName} /> :
                                                <img src={sortDownImage} className="sortImage" onClick={sortByFileName} />
                                        }
                                    </th>
                                    <th className="middleColumnWidth">Recipient
                                        <img ref={emailDomainTarget} src={filterImage} className="sortImage filterImage"
                                            onClick={openFilterByEmailDomain} />
                                        {overlay(emailDomainTarget, filterByRecipient, showEmailDomainFilter, openFilterByEmailDomain)}
                                        {recipientSortType == "asc" ?
                                            <img src={sortUpImage} className="sortImage" onClick={sortByRecipient} /> :
                                            <img src={sortDownImage} className="sortImage" onClick={sortByRecipient} />
                                        }</th>
                                    <th className="middleColumnWidth" onClick={sortByDate}>
                                        Date Sent
                                        {dateSentSortType == "asc" ?
                                            <img src={sortUpImage} className="sortImage" /> :
                                            <img src={sortDownImage} className="sortImage" />
                                        }
                                    </th>
                                    <th className="middleColumnWidth">View File</th>
                                    <th className="middleColumnWidth">Downloads</th>
                                    {currentUser.isAdmin ? <th className="middleColumnWidth">Days Left</th> : <></>}
                                </tr>
                            </thead>
                            <tbody className="rowWidth">
                                {usersTableBody}
                            </tbody>
                        </Table>
                    </div>
                </div>
            )
        } else {
            return <Redirect to="/login" />
        }
    }
}

const ControlPanel = connect(state => ({
    ...state
}))(_ControlPanel);

export default withRouter(ControlPanel)