import React, { Component } from 'react';
import { Route, Redirect, Switch, BrowserRouter as Router } from 'react-router-dom'
import Home from './components/Home'
import Header from './components/common/header'
import ControlPanel from './components/ControlPanel'
import Login from './components/Login'
import Registration from './components/Registration'
import Users from './components/Users'

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = !!localStorage.getItem('token');

    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
};

const PublicRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = !!localStorage.getItem('token');

    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    <Redirect to="/home" />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};

export default class App extends Component {
    componentDidMount() {
        document.body.style.backgroundColor = "white";
    }

    render() {
        return (
            <div className="mainDiv">
                <Header />
                <Router>
                    <Switch>
                        <PublicRoute exact path="/" component={Login} />
                        <PublicRoute exact path="/login" component={Login} />
                        <PublicRoute exact path="/registration" component={Registration} />
                        <ProtectedRoute exact path="/home" component={Home} />
                        <ProtectedRoute exact path="/history" component={ControlPanel} />
                        <ProtectedRoute exact path="/users" component={Users} />
                    </Switch>
                </Router>
            </div>
        );
    }
}
