import React, { useEffect, useState } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Actions } from '../../store/actions'
import NavBar from '../common/navbar/index'
import { Button, ButtonGroup, Modal, Alert, Container, Row, Col, Form, ProgressBar } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown'
import { connect } from 'react-redux';
import trashImage from '../../assets/img/trash.png';
import sortDownImage from '../../assets/img/sort_down.png';
import sortUpImage from '../../assets/img/sort_up.png';

function _Home(props) {
    let dispatch = useDispatch();
    let folders = props.folder.folders;
    let foldersNames = props.folder.foldersNames;
    let creationParentFolder = props.createFolder.parentFolder;
    let uploadParentFolder = props.uploadFile.parentFolder;
    let newFolderName = props.createFolder.newFolder;
    let shownFolders = props.folder.shownFolders;
    let action = props.actions.action;
    let param = props.actions.param;
    let showCreateFolderErrorWindow = props.homeComponent.showCreateFolderErrorWindow;
    let showUploadFileErrorWindow = props.homeComponent.showUploadFileErrorWindow;
    let showGetFileLinkErrorWindow = props.homeComponent.showGetFileLinkErrorWindow;
    let showRemoveFolderErrorWindow = props.homeComponent.showRemoveFolderErrorWindow;
    let showRemoveFileErrorWindow = props.homeComponent.showRemoveFileErrorWindow;
    let errorWindowMessage = props.homeComponent.errorWindowMessage;
    let isLoading = props.isLoading;
    let fileReceiverEmail = props.uploadFile.fileReceiverEmail;
    let showNewFolderNameWindow = props.changeFolderName.showNewFolderNameWindow;
    let updatedFolder = props.changeFolderName.updatedFolder;
    let showUpdateFolderNameErrorWindow = props.homeComponent.showUpdateFolderNameErrorWindow;
    let showReceiversWindow = props.receivers.showReceiversWindow;
    let receivers = props.receivers.receivers;
    let showReceiversErrorWindow = props.receivers.showReceiversErrorWindow;
    let receiverFileId = props.receivers.fileId;
    let showHomeSuccessWindow = props.homeComponent.showHomeSuccessWindow;
    let fileNameSortType = props.homeComponent.fileNameSortType;
    let recipientSortType = props.homeComponent.recipientSortType;
    let dateSentSortType = props.homeComponent.dateSentSortType;
    let file;
    let reader = new FileReader();
    let uploadingStatus = props.homeComponent.uploadingStatus;
    let showUploadProgress = props.homeComponent.showUploadProgress;
    let chunk_size = 3 * 1024 * 1024;//3 mb

    //const handleWindowBeforeUnload = e => {
    //    if (window.performance && performance.navigation.type != 1) {
    //        window.localStorage.removeItem("token");
    //    }
    //};

    //window.addEventListener('beforeunload', handleWindowBeforeUnload);

    const [show, setShow] = useState(false);

    const SORT_FIELDS = {
        fileName: 0,
        recipient: 1,
        dateSent: 2
    }

    const handleClose = () => setShow(false);

    const handleAction = () => {
        if (action) {
            action(param);
        }
    };

    const getFoldersForUser = async (sortField, sortType, topFileName) => {
        var token = window.localStorage.getItem("token");
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token, sortField, sortType, topFileName })
        }
        dispatch(Actions.setIsLoading(true));
        await fetch("/Home/GetFoldersForUser", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        dispatch(Actions.setFolders(result.data.folders));
                        dispatch(Actions.setFoldersNames(result.data.foldersNames));
                        shownFolders = result.data.folders.map((e, i) => { return e.folderName });
                        dispatch(Actions.setShownFolders(shownFolders));
                        dispatch(Actions.setIsLoading(false));
                    } else {
                        dispatch(Actions.setFolders([]));
                        dispatch(Actions.setFoldersNames([]));
                        dispatch(Actions.setShownFolders([]));
                        dispatch(Actions.setIsLoading(false));
                    }
                },
                (error) => {
                    dispatch(Actions.setFolders([]));
                    dispatch(Actions.setFoldersNames([]));
                    dispatch(Actions.setShownFolders([]));
                    dispatch(Actions.setIsLoading(false));
                }
            )
    };

    useEffect(() => {
        getFoldersForUser();
    }, [])

    const onRemoveFolder = (e) => {
        dispatch(Actions.setAction(removeFolder));
        dispatch(Actions.setParam(e.currentTarget.id));
        setShow(true);
    }

    const onRemoveFile = (e) => {
        dispatch(Actions.setAction(removeFile));
        dispatch(Actions.setParam(e.currentTarget.id));
        setShow(true);
    }

    const sort = (e) => {
        var sortField = SORT_FIELDS[e.currentTarget.getAttribute("name")];

        var sortType;
        switch (sortField) {
            case 0:
            default: {
                dispatch(Actions.setChangeFileNameSortType());
                sortType = props.homeComponent.fileNameSortType;
                break;
            }
            case 1: {
                dispatch(Actions.setChangeRecipientSortType());
                sortType = props.homeComponent.recipientSortType;
                break;
            }
            case 2: {
                dispatch(Actions.setChangeDateSentSortType());
                sortType = props.homeComponent.dateSentSortType;
                break;
            }
        }

        getFoldersForUser(sortField, sortType);
    }

    const onGetLink = (e) => {
        var token = window.localStorage.getItem("token");
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token, fileId: e.currentTarget.id })
        }
        dispatch(Actions.setIsLoading(true));
        fetch("/Link/GetFileLink", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        dispatch(Actions.setIsLoading(false));
                        window.open(result.data.url + "local");
                    } else if (result.message) {
                        alert(result.message);
                        dispatch(Actions.setIsLoading(true));
                        dispatch(Actions.setIsLoading(false));
                    } else {
                        dispatch(Actions.setShowGetFileLinkErrorWindow(true));
                        dispatch(Actions.setIsLoading(false));
                        dispatch(Actions.setIsLoading(true));
                    }
                },
                (error) => {
                    dispatch(Actions.setShowGetFileLinkErrorWindow(true));
                    dispatch(Actions.setIsLoading(false));
                    dispatch(Actions.setIsLoading(true));
                }
            );
    }

    const onOpenReceiversWindow = (e) => {
        props.receivers.receivers = [{ id: 1, email: "" }];
        dispatch(Actions.setEditReceiverFileId(e.currentTarget.id));
        dispatch(Actions.setShowReceiversWindow(true));
    }

    const handleReceiverChange = (e) => {
        var element = document.getElementById(e.currentTarget.id);
        dispatch(Actions.setEditReceiver({ id: element.id.replace("receiver", ""), email: e.currentTarget.value }));
    }

    const receiverForm = (receiver) => {
        return (
            <Form className="mb-3" controlid={"receiver" + receiver.id} key={"receiver" + receiver.id}>
                <Form.Group>
                    <Form.Label className="mb-3">Email address</Form.Label>
                    <Form.Control className="mb-3" type="email" id={"receiver" + receiver.id} value={receiver.email} onChange={handleReceiverChange} placeholder="Enter email" />
                </Form.Group>
            </Form>
        )
    }

    const _handleAddReceiver = () => {
        var receiver = { id: receivers.length + 1, email: "" };
        dispatch(Actions.setAddReceiver(receiver));
    }
    const _handleDeleteReceiver = () => {
        if (props.receivers.receivers.length > 1) {
            props.receivers.receivers.pop();
            dispatch(Actions.setShowReceiversWindow(true));
        }
        //dispatch(Actions.setAddReceiver(receiver));
    }


    const _handleReceiversWindowClose = () => {
        dispatch(Actions.setShowReceiversWindow(false));
    }

    const onAddFileToForm = (e) => {
        //receivers.map((item, index) => {
        //    fileReceiverEmail = item.email;
        //    onUploadNewFile(e.currentTarget.id);
        //});
        fileReceiverEmail = receivers.map((e, i) => { return e.email }).join();
        onUploadNewFile(e.currentTarget.id);
        dispatch(Actions.setShowReceiversWindow(false));
    }

    const onAddUploadingFileToForm = (isPartOfFile, payload, callback) => {
        onUploadNewFile(null, isPartOfFile, payload, callback);
    }

    const onHandleChangeComments = (e) => {
        var value = e.currentTarget.value;
        var fileId = e.currentTarget.id;
        setFileField(folders[0], fileId, "comments", value);
    }

    const getFileById = (folder, fileId) => {
        var file = [];
        if (folder.files.length) {
            file = folder.files.filter((e, i) => {
                return e.fileId == fileId;
            });
        }
        if (!file.length && folder.subFolders.length) {
            folder.subFolders.map((e, i) => {
                file = getFileById(e, fileId);
                if (file.length) {
                    return;
                }
            });
        }

        return file;
    }

    const setFileField = (source, fileId, fieldName, fieldValue) => {
        var result = false;
        if (source.files.length) {
            source.files.map((e, i) => {
                if (e.fileId == fileId) {
                    e[fieldName] = fieldValue;
                    result = true;
                    return;
                } else {

                }
            });
        } else if (source.subFolders.length) {
            source.subFolders.map((e, i) => {
                result = setFileField(e, fileId, fieldName, fieldValue);
                if (result) {
                    return;
                }
            });
        }

        return result;
    }

    const onSendEmails = (e) => {
        var fileId = e.currentTarget.id;
        var receiver = document.getElementById("file_" + e.currentTarget.id).children.namedItem("receiverEmail").innerHTML;
        var file = getFileById(folders[0], fileId)[0];
        dispatch(Actions.setShowReceiversWindow(false));
        var token = window.localStorage.getItem("token");
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ receiver, token, fileId, comments: file.comments })
        }
        dispatch(Actions.setIsLoading(true));
        fetch("/Home/SendNotification", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        dispatch(Actions.setIsLoading(false));
                        dispatch(Actions.setShowReceiversErrorWindow(false));
                        dispatch(Actions.setShowHomeSuccessWindow(true));
                        getFoldersForUser();

                    } else {
                        dispatch(Actions.setShowReceiversErrorWindow(true));
                        dispatch(Actions.setIsLoading(false));
                    }
                },
                (error) => {
                    dispatch(Actions.setShowReceiversErrorWindow(true));
                    dispatch(Actions.setIsLoading(false));
                }
            );
    }

    const receiversWindow = (
        <Modal show={showReceiversWindow} onHide={_handleReceiversWindowClose}>
            <Modal.Header closeButton>
                <Modal.Title>Receivers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    receivers.map((e, i) => { return receiverForm(e); })
                }
                <Button variant="secondary" onClick={_handleAddReceiver}>
                    Add
                </Button>
                <Button className="textCenter" variant="danger" block="true" onClick={_handleDeleteReceiver}>
                    Delete(Last)
                </Button>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark" onClick={_handleReceiversWindowClose}>
                    Close
                </Button>
                <Button variant="primary" id={receiverFileId} onClick={onAddFileToForm}>
                    Continue
                </Button>
            </Modal.Footer>
        </Modal>
    )

    const ActiveFilesFormRow = (file, folderId) => {
        return (
            <>
                <div className="flexColumn mediumBottomMargin" id={"file_" + file.fileId}>
                    <label className="hidden" name="fileId">{file.fileId}</label>
                    <label className="hidden" name="receiverEmail">{file.receiverEmail}</label>
                    <Row>
                        <Col sm={5} md={5} lg={5} xl={5}>
                            <label>{file.fileName}</label>
                        </Col>

                        <Col sm={3} md={3} lg={3} xl={3}>
                            {
                                file.receiverEmail ?
                                    <label>{file.receiverEmail}</label> :
                                    <Button variant="link" id={file.fileId} onClick={onOpenReceiversWindow}>Send To</Button>
                            }
                        </Col>

                        <Col sm={1} md={1} lg={1} xl={1}>
                            <label>{file.sendingDate ? new Date(file.sendingDate).toLocaleDateString('en-CA') : ''}</label>
                        </Col>

                        <Col sm={1} md={1} lg={1} xl={1}>
                            <label>{file.daysLeftBeforeDeactivation}</label>
                        </Col>

                        <Col sm={1} md={1} lg={1} xl={1}>
                            <label>{file.downloadsCount}</label>
                        </Col>

                        <Col sm={1} md={1} lg={1} xl={1}>
                            <Button variant="link" id={file.fileId} onClick={onGetLink}>View File</Button>
                            <hr />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={8} md={8} lg={8} xl={8}>
                            <textarea id={file.fileId} className="fullSize" placeholder="Comments" defaultValue={file.comments} onChange={onHandleChangeComments} />
                        </Col>

                        <Col sm={1} md={1} lg={1} xl={1}>

                        </Col>

                        <Col sm={1} md={1} lg={1} xl={1}>

                        </Col>

                        <Col sm={1} md={1} lg={1} xl={1}>

                        </Col>

                        <Col sm={1} md={1} lg={1} xl={1}>
                            <div >
                                <Button className="fileActionsButton" variant="primary" id={file.fileId} onClick={onSendEmails}>Send</Button>

                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={11} md={11} lg={11} xl={11}>

                        </Col>
                        <Col sm={1} md={1} lg={1} xl={1}>
                            <Button className="fileActionsButton" variant="danger" id={file.fileId} onClick={onRemoveFile}>Delete</Button>
                        </Col>



                    </Row>
                    <hr className="bottomLine" />
                </div>
            </>
        );
    };

    const showSubElements = (e) => {
        var folders = shownFolders.slice();
        folders.push(e.currentTarget.id)
        dispatch(Actions.setShownFolders(folders));
    }

    const hideSubElements = (e) => {
        dispatch(Actions.setShownFolders(shownFolders.filter((i) => { return i !== e.currentTarget.id })));
    }

    const onEditFolderName = (e) => {
        dispatch(Actions.setUpdatedFolderId(e.currentTarget.id));
        var value = e.currentTarget.parentElement.innerText.substring(0, e.currentTarget.parentElement.innerText.indexOf("\n"));
        dispatch(Actions.setUpdatedFolderName(value));
        dispatch(Actions.setShowNewFolderNameWindow(true));
    }

    const FolderNameRow = (folder, margin = 0) => {
        return (
            <>
                <div style={{ marginLeft: margin + 'px', marginBottom: '20px' }}>
                    <div className="folderNameDiv">
                    </div>
                    {folder.folderName && folder.folderName != "Root" && (<img src={trashImage} id={folder.folderId} className="imgSize horizontalMargins" onClick={onRemoveFolder} />)}
                </div>
            </>
        );
    }

    const ActiveFilesFormFolder = (folder, margin = 0) => {
        return (
            <>
                {
                    FolderNameRow(folder, margin)
                }
                {
                    (folder.files.length && shownFolders.includes(folder.folderName) ? folder.files.map((item, index) => {
                        return ActiveFilesFormRow(item, folder.folderId);
                    }) : <></>)
                }
                {
                    (folder.subFolders.length && shownFolders.includes(folder.folderName) ? folder.subFolders.map((item, index) => {
                        return ActiveFilesFormFolder(item, margin + 10);
                    }) : <></>)
                }
            </>
        );
    }

    const ActiveFilesForm = folders.map((item, index) => {
        return ActiveFilesFormFolder(item);
    });

    const handleChange = (e) => {
        dispatch(Actions.setNewFolderName(e.currentTarget.value));
    }

    const AlertDismissible = (params) => {
        if (params.show) {
            return (
                <Alert variant="danger" onClose={() => dispatch(params.action(false))} dismissible>
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    <p>
                        {errorWindowMessage || params.message}
                    </p>
                </Alert>
            );
        } else {
            return null;
        }
    }

    const SuccessAlert = (params) => {
        if (params.show) {
            return (
                <Alert variant="success" onClose={() => dispatch(params.action(false))} dismissible>
                    <p>
                        {params.message}
                    </p>
                </Alert>
            );
        } else {
            return null;
        }
    }

    const onCreateNewFolder = () => {
        var token = window.localStorage.getItem("token");
        var folderInfo = {
            parentFolder: creationParentFolder,
            folderName: newFolderName
        }

        //Clear the textField for the folder name
        props.createFolder.newFolder = "";
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...folderInfo, token })
        }
        dispatch(Actions.setIsLoading(true));
        fetch("/Home/CreateFolder", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        getFoldersForUser();
                        dispatch(Actions.setIsLoading(false));
                    } else {
                        dispatch(Actions.setHomeComponentErrorMessage(result.message));
                        dispatch(Actions.setShowCreateFolderErrorWindow(true));
                        dispatch(Actions.setIsLoading(false));
                    }
                },
                (error) => {
                    dispatch(Actions.setShowCreateFolderErrorWindow(true));
                    dispatch(Actions.setIsLoading(false));
                }
            );
    }

    const cleanUploadInput = () => {
        dispatch(Actions.setUploadParentFolderName(""));
        document.getElementById("inputGroupFile").value = "";
        document.getElementById("uploadFileLable").innerText = "";
        dispatch(Actions.setUploadFileReceiverEmail(""));
    }

    const uploadProgress = (
        <div id="uploadProgressBar2" style={{ display: showUploadProgress ? "flex" : "none" }}>
            <div id="uploadProgressBar">
                <ProgressBar id="uploadProgress" animated variant="info" now={uploadingStatus} label={`Uploading: ${uploadingStatus}%`} />
            </div>
        </div>
    );

    const _uploadChunk = (file, offset, range) => {
        if (offset >= file.size) {
            onAddUploadingFileToForm(false);
            dispatch(Actions.setShowUploadProgress(false));
            return;
        }

        reader.addEventListener('load', function (e) {
            var filename = file.name;
            var index = offset / chunk_size;
            var data = e.target.result.split(';base64,')[1];

            var payload = {
                filename: filename,
                index: index,
                base64: data,
            };

            onAddUploadingFileToForm(true, payload, function () {
                var progressStep = Math.round(100 / Math.round(file.size / chunk_size));
                dispatch(Actions.setUploadProgressBarStatus(index * progressStep));
                _uploadChunk(file, offset + range, chunk_size);
            });
        }, { once: true });
        dispatch(Actions.setShowUploadProgress(true));

        var chunk = file.slice(offset, offset + range);
        reader.readAsDataURL(chunk);
    }

    const handleFileChange = (e) => {
        file = e.target.files[0];
        dispatch(Actions.setUploadProgressBarStatus(0));
        _uploadChunk(file, 0, chunk_size);
    }

    const onUploadNewFile = (fileName, isPartOfFile = false, payload, callback) => {
        var token = window.localStorage.getItem("token");
        var fileInfo = {
            ...payload,
            parentFolder: uploadParentFolder,
            //base64: uploadFile.base64,
            //fileName: fileName || uploadFile.file.name,
            receiverEmail: fileReceiverEmail,
            isPartOfFile: isPartOfFile
        }
        fileInfo.fileName = fileName || file.name;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...fileInfo, token })
        }
        dispatch(Actions.setIsLoading(true));
        fetch("/Home/UploadFile", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        if (callback) {
                            callback();
                            return true;
                        }
                        getFoldersForUser(undefined, undefined, fileInfo.fileName);
                    } else {
                        dispatch(Actions.setHomeComponentErrorMessage(result.message));
                        dispatch(Actions.setShowUploadFileErrorWindow(true));
                        setTimeout(Actions.setShowUploadFileErrorWindow(false), 4000);
                    }
                },
                (error) => {
                    console.log(error);
                    dispatch(Actions.setShowUploadFileErrorWindow(true));
                    setTimeout(Actions.setShowUploadFileErrorWindow(false), 4000);
                }
            )
            .then(() => {
                if (!callback) {
                    dispatch(Actions.setIsLoading(false));
                }
            })
            .then(() => {
                if (!callback) {
                    cleanUploadInput();
                }
            });
    }

    //const onCopyLink = (e) => {
    //    var token = window.localStorage.getItem("token");
    //    const requestOptions = {
    //        method: 'POST',
    //        headers: { 'Content-Type': 'application/json' },
    //        body: JSON.stringify({ token, fileId: e.currentTarget.id })
    //    }
    //    dispatch(Actions.setIsLoading(true));
    //    fetch("/Home/GetLinkForFile", requestOptions)
    //        .then(res => res.json())
    //        .then(
    //            (result) => {
    //                if (result.status === "Success") {
    //                    dispatch(Actions.setIsLoading(false));
    //                    navigator.clipboard.writeText(document.location.host + "/" + result.data.url).then(() => { alert(`Copied!`) }).catch((error) => { alert(`Copy failed! ${error}`) })
    //                } else if (result.message) {
    //                    alert(result.message);
    //                    dispatch(Actions.setIsLoading(true));
    //                    dispatch(Actions.setIsLoading(false));
    //                } else {
    //                    dispatch(Actions.setShowGetFileLinkErrorWindow(true));
    //                    dispatch(Actions.setIsLoading(false));
    //                    dispatch(Actions.setIsLoading(true));
    //                }
    //            },
    //            (error) => {
    //                dispatch(Actions.setShowGetFileLinkErrorWindow(true));
    //                dispatch(Actions.setIsLoading(false));
    //                dispatch(Actions.setIsLoading(true));
    //            }
    //        );
    //}

    const removeFile = (e) => {
        handleClose();
        var token = window.localStorage.getItem("token");
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token, fileId: e })
        }
        dispatch(Actions.setIsLoading(true));
        fetch("/Home/RemoveFile", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        getFoldersForUser();
                        dispatch(Actions.setIsLoading(false));
                    } else {
                        dispatch(Actions.setShowRemoveFileErrorWindow(true));
                        dispatch(Actions.setIsLoading(false));
                    }
                },
                (error) => {
                    dispatch(Actions.setShowRemoveFileErrorWindow(true));
                    dispatch(Actions.setIsLoading(false));
                }
            );
    }

    const removeFolder = (e) => {
        handleClose();
        var token = window.localStorage.getItem("token");
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token, folderId: e })
        }
        dispatch(Actions.setIsLoading(true));
        fetch("/Home/RemoveFolder", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        getFoldersForUser();
                        dispatch(Actions.setIsLoading(false));
                    } else {
                        dispatch(Actions.setShowRemoveFolderErrorWindow(true));
                        dispatch(Actions.setIsLoading(false));
                    }
                },
                (error) => {
                    dispatch(Actions.setShowRemoveFolderErrorWindow(true));
                    dispatch(Actions.setIsLoading(false));
                }
            );
    }

    const onSetNewFolderName = (e) => {
        dispatch(Actions.setShowNewFolderNameWindow(false));
        var token = window.localStorage.getItem("token");
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...updatedFolder, token })
        }
        dispatch(Actions.setIsLoading(true));
        fetch("/Home/ChangeFolderName", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        getFoldersForUser();
                        dispatch(Actions.setIsLoading(false));
                    } else {
                        dispatch(Actions.setHomeComponentErrorMessage(result.message));
                        dispatch(Actions.setShowUpdateFolderNameErrorWindow(true));
                        setTimeout(Actions.setShowUpdateFolderNameErrorWindow(false), 4000);
                        dispatch(Actions.setIsLoading(false));
                        cleanUploadInput();
                    }
                },
                (error) => {
                    dispatch(Actions.setShowUpdateFolderNameErrorWindow(true));
                    setTimeout(Actions.setShowUpdateFolderNameErrorWindow(false), 4000);
                    dispatch(Actions.setIsLoading(false));
                }
            );

        dispatch(Actions.setUpdatedFolder({ folderId: "", folderName: "" }));
    }

    const handleFolderNameChange = (e) => {
        dispatch(Actions.setUpdatedFolderName(e.currentTarget.value));
    }

    const handleEditWindowClose = () => {
        dispatch(Actions.setShowNewFolderNameWindow(false));
    }

    const handleCreationFolderChange = (e) => {
        if (e.currentTarget.innerText === "Root") {
            dispatch(Actions.setCreationParentFolderName(""));
        } else {
            dispatch(Actions.setCreationParentFolderName(e.currentTarget.innerText));
        }
        let selected = document.getElementById("dropdown-basic");
        selected.innerText = e.target.innerText;
    }

    const handleUploadFolderChange = (e) => {
        cleanUploadInput();
        if (e.currentTarget.innerText === "Root") {
            dispatch(Actions.setUploadParentFolderName(""));
        } else {
            dispatch(Actions.setUploadParentFolderName(e.currentTarget.innerText));
        }
        let selected = document.getElementById("dropdown-basic2");
        selected.innerText = e.target.innerText;
    }

    const handleReceiverEmailChange = (e) => {
        dispatch(Actions.setUploadFileReceiverEmail(e.currentTarget.value));
    }

    const creationFolderNames = foldersNames.slice().map((folder, index) =>
        <Dropdown.Item key={index} onClick={handleCreationFolderChange}>{folder}</Dropdown.Item>
    )

    const uploadFolderNames = foldersNames.slice().map((folder, index) =>
        <Dropdown.Item key={index} onClick={handleUploadFolderChange}>{folder}</Dropdown.Item>
    )

    const _Loader = () => {
        if (isLoading) {
            return (
                <div className="loaderRow">
                    <div className="loaderColumn">
                        <div id="homeLoader" className="spinner-border loader" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="ms-2">
                            Loading...
                        </div>
                    </div>
                </div>
            )
        } else {
            return (<></>)
        }
    }

    const Confirmation = () => {
        return (
            <>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="dark" onClick={handleClose}>
                            No
                        </Button>
                        <Button variant="primary" onClick={handleAction}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    if (isLoading) {
        return (
            <>
                {showUploadProgress ? uploadProgress : <_Loader />}
            </>
        )
    } else {
        if (folders.length && folders[0].userName || window.localStorage.getItem("token")) {
            return (
                <div className="homeDiv">
                    <NavBar></NavBar>
                    <Modal show={showNewFolderNameWindow} onHide={handleEditWindowClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Change Folder Name</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group controlid="newFolderName">
                                    <Form.Label>Folder Name</Form.Label>
                                    <Form.Control placeholder="Folder Name" onChange={handleFolderNameChange} controlid="folderName" id="folderName" value={updatedFolder.folderName} />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleEditWindowClose}>
                                Close
                            </Button>
                            <Button variant="secondary" onClick={onSetNewFolderName}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                        <AlertDismissible />
                    </Modal>
                    <div className="flexColumn fullWidth mediumPaddings mediumRightMargin">
                        <div className="homeMainDiv fullWidth">
                            <AlertDismissible message="Error! Folder wasn't removed." show={showRemoveFolderErrorWindow} action={Actions.setShowRemoveFolderErrorWindow} />
                            <AlertDismissible message="Error! Link wasn't created." show={showGetFileLinkErrorWindow} action={Actions.setShowGetFileLinkErrorWindow} />
                            <AlertDismissible message="Error! File wasn't removed." show={showRemoveFileErrorWindow} action={Actions.setShowRemoveFileErrorWindow} />
                            <AlertDismissible message="Error! Folder wasn't created." show={showCreateFolderErrorWindow} action={Actions.setShowCreateFolderErrorWindow} />
                            <AlertDismissible message="Error! File wasn't uploaded." show={showUploadFileErrorWindow} action={Actions.setShowUploadFileErrorWindow} />
                            <AlertDismissible message="Error! Folder name wasn't changed." show={showUpdateFolderNameErrorWindow} action={Actions.setShowUpdateFolderNameErrorWindow} />
                            <AlertDismissible message="Error! Notification wasn't sent." show={showReceiversErrorWindow} action={Actions.setShowReceiversErrorWindow} />
                            <SuccessAlert message="Success" show={showHomeSuccessWindow} action={Actions.setShowHomeSuccessWindow} />
                            <Confirmation />
                            <h3>File sharing:</h3>
                            <ButtonGroup vertical className="buttonGroup">
                                <div id="uploadFileDiv" className="homeRowDiv standardMargins createUploadDivBackground">
                                    <div className="verticalCenter">
                                        <label className="standardMargins">Documents</label>
                                    </div>
                                    <div className="verticalCenter">
                                        <div className="input-group standardMargins">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="inputGroupFile" onChange={handleFileChange} />
                                                <label className="custom-file-label" id="uploadFileLable"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ButtonGroup>
                        </div>
                        <div className="mediumBottomMargin">
                            <Row>
                                <Col sm={5} md={5} lg={5} xl={5}>
                                    <label name="fileName" onClick={sort}>
                                        File Name
                                        {fileNameSortType == "asc" ?
                                            <img src={sortUpImage} className="sortImage" /> :
                                            <img src={sortDownImage} className="sortImage" />
                                        }
                                    </label>
                                </Col>

                                <Col sm={3} md={3} lg={3} xl={3}>
                                    <label name="recipient" onClick={sort}>
                                        Recipient
                                        {recipientSortType == "asc" ?
                                            <img src={sortUpImage} className="sortImage" /> :
                                            <img src={sortDownImage} className="sortImage" />
                                        }
                                    </label>
                                </Col>

                                <Col sm={1} md={1} lg={1} xl={1}>
                                    <label name="dateSent" onClick={sort}>
                                        Date Sent
                                        {dateSentSortType == "asc" ?
                                            <img src={sortUpImage} className="sortImage" /> :
                                            <img src={sortDownImage} className="sortImage" />
                                        }
                                    </label>
                                </Col>

                                <Col sm={1} md={1} lg={1} xl={1}>
                                    <label name="daysLeftBeforeDeactivation">
                                        Days Left
                                    </label>
                                </Col>

                                <Col sm={1} md={1} lg={1} xl={1}>
                                    <label>Downloads</label>
                                </Col>

                                <Col sm={1} md={1} lg={1} xl={1}>

                                </Col>
                            </Row>
                            <hr />
                        </div>
                        {ActiveFilesForm}
                        {receiversWindow}
                    </div>
                </div>
            )
        } else {
            return <Redirect to="/login" />
        }
    }
}

const Home = connect(state => ({
    ...state
}))(_Home);

export default withRouter(Home)