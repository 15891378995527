import { Actions as ActionNames } from '../constants/actionsConstants'

const setCurrentUser = (payload) => {
    return {
        type: ActionNames.SET_CURRENT_USER,
        payload
    }
}

const setAuthUserName = (payload) => {
    return {
        type: ActionNames.SET_AUTH_USERNAME,
        payload
    }
}

const setAuthPwd = (payload) => {
    return {
        type: ActionNames.SET_AUTH_PWD,
        payload
    }
}

const setAuthConfirmPwd = (payload) => {
    return {
        type: ActionNames.SET_AUTH_CONFIRM_PWD,
        payload
    }
}

const setIsSuccessPwdChange = (payload) => {
    return {
        type: ActionNames.SET_IS_SUCCESS_PWD_CHANGE,
        payload
    }
}

const setNewPwd = (payload) => {
    return {
        type: ActionNames.SET_NEW_PWD,
        payload
    }
}

const setNewConfirmPwd = (payload) => {
    return {
        type: ActionNames.SET_NEW_CONFIRM_PWD,
        payload
    }
}

const setOldPwd = (payload) => {
    return {
        type: ActionNames.SET_OLD_PWD,
        payload
    }
}

const setFolders = (payload) => {
    return {
        type: ActionNames.SET_FOLDERS,
        payload
    }
}

const setFoldersNames = (payload) => {
    return {
        type: ActionNames.SET_FOLDERS_NAMES,
        payload
    }
}

const setCreationParentFolderName = (payload) => {
    return {
        type: ActionNames.SET_CREATION_PARENT_FOLDER_NAME,
        payload
    }
}

const setUploadParentFolderName = (payload) => {
    return {
        type: ActionNames.SET_UPLOAD_PARENT_FOLDER_NAME,
        payload
    }
}

const setUploadFile = (payload) => {
    return {
        type: ActionNames.SET_UPLOAD_FILE,
        payload
    }
}

const setNewFolderName = (payload) => {
    return {
        type: ActionNames.SET_NEW_FOLDER_NAME,
        payload
    }
}

const setAction = (payload) => {
    return {
        type: ActionNames.SET_ACTION,
        payload
    }
}

const setParam = (payload) => {
    return {
        type: ActionNames.SET_PARAM,
        payload
    }
}

const setUsers = (payload) => {
    return {
        type: ActionNames.SET_USERS,
        payload
    }
}

const setSelectedLink = (payload) => {
    return {
        type: ActionNames.SET_SELECTED_LINK,
        payload
    }
}

const setShowEditWindow = (payload) => {
    return {
        type: ActionNames.SET_SHOW_EDIT_WINDOW,
        payload
    }
}

const setSelectedUser = (payload) => {
    return {
        type: ActionNames.SET_SELECTED_USER,
        payload
    }
}

const setIsSuccessAuth = (payload) => {
    return {
        type: ActionNames.SET_IS_SUCCESS_AUTH,
        payload
    }
}

const setActiveMenuOption = (payload) => {
    return {
        type: ActionNames.SET_ACTIVE_MENU_OPTION,
        payload
    }
}

const setShowNewPwdWindow = (payload) => {
    return {
        type: ActionNames.SET_SHOW_NEW_PWD_WINDOW,
        payload
    }
}

const setIsAdmin = (payload) => {
    return {
        type: ActionNames.SET_IS_ADMIN,
        payload
    }
}

const setEditUserName = (payload) => {
    return {
        type: ActionNames.SET_EDIT_USER_NAME,
        payload
    }
}

const setEditPwd = (payload) => {
    return {
        type: ActionNames.SET_EDIT_PWD,
        payload
    }
}

const setIsSuccessEdit = (payload) => {
    return {
        type: ActionNames.SET_IS_SUCCESS_EDIT,
        payload
    }
}

const setSelectedForEditUser = (payload) => {
    return {
        type: ActionNames.SET_SELECTED_FOR_EDIT_USER,
        payload
    }
}

const setShownFolders = (payload) => {
    return {
        type: ActionNames.SET_SHOWN_FOLDERS,
        payload
    }
}

const setEditIsAdmin = (payload) => {
    return {
        type: ActionNames.SET_EDIT_IS_ADMIN,
        payload
    }
}

const setActivePageNumber = (payload) => {
    return {
        type: ActionNames.SET_ACTIVE_PAGE_NUMBER,
        payload
    }
}

const setPages = (payload) => {
    return {
        type: ActionNames.SET_PAGES,
        payload
    }
}

const setShowCreateFolderErrorWindow = (payload) => {
    return {
        type: ActionNames.SET_SHOW_CREATE_FOLDER_ERROR_WINDOW,
        payload
    }
}

const setShowUploadFileErrorWindow = (payload) => {
    return {
        type: ActionNames.SET_SHOW_UPLOAD_FILE_ERROR_WINDOW,
        payload
    }
}

const setShowGetFileLinkErrorWindow = (payload) => {
    return {
        type: ActionNames.SET_SHOW_GET_FILE_LINK_ERROR_WINDOW,
        payload
    }
}

const setShowRemoveFolderErrorWindow = (payload) => {
    return {
        type: ActionNames.SET_SHOW_REMOVE_FILE_ERROR_WINDOW,
        payload
    }
}

const setShowRemoveFileErrorWindow = (payload) => {
    return {
        type: ActionNames.SET_SHOW_REMOVE_FILE_ERROR_WINDOW,
        payload
    }
}

const setShowRemoveUserErrorWindow = (payload) => {
    return {
        type: ActionNames.SET_SHOW_REMOVE_USER_ERROR_WINDOW,
        payload
    }
}

const setShowReactivateLinkErrorWindow = (payload) => {
    return {
        type: ActionNames.SET_SHOW_REACTIVATE_LINK_ERROR_WINDOW,
        payload
    }
}

const setHomeComponentErrorMessage = (payload) => {
    return {
        type: ActionNames.SET_HOME_COMPONENT_ERROR_WINDOW_MESSAGE,
        payload
    }
}

const setIsLoading = (payload) => {
    return {
        type: ActionNames.SET_IS_LOADING,
        payload
    }
}

const setIsAuthLoading = (payload) => {
    return {
        type: ActionNames.SET_IS_AUTH_LOADING,
        payload
    }
}

const setUploadFileReceiverEmail = (payload) => {
    return {
        type: ActionNames.SET_UPLOAD_FILE_RECEIVER_EMAIL,
        payload
    }
}

const setShowCreateWindow = (payload) => {
    return {
        type: ActionNames.SET_SHOW_CREATE_WINDOW,
        payload
    }
}

const setCreationIsAdmin = (payload) => {
    return {
        type: ActionNames.SET_CREATION_IS_ADMIN,
        payload
    }
}

const setCreationUserName = (payload) => {
    return {
        type: ActionNames.SET_CREATION_USER_NAME,
        payload
    }
}

const setCreationPwd = (payload) => {
    return {
        type: ActionNames.SET_CREATION_PWD,
        payload
    }
}

const setIsSuccessCreation = (payload) => {
    return {
        type: ActionNames.SET_IS_SUCCESS_CREATION,
        payload
    }
}

const setCreateUserName = (payload) => {
    return {
        type: ActionNames.SET_CREATION_USER_NAME,
        payload
    }
}

const setCreatePwd = (payload) => {
    return {
        type: ActionNames.SET_CREATION_PWD,
        payload
    }
}

const setCreateIsAdmin = (payload) => {
    return {
        type: ActionNames.SET_CREATION_IS_ADMIN,
        payload
    }
}

const setUserForAction = (payload) => {
    return {
        type: ActionNames.SET_USER_FOR_ACTION,
        payload
    }
}

const setShowActionErrorWindow = (payload) => {
    return {
        type: ActionNames.SET_SHOW_ACTION_ERROR_WINDOW,
        payload
    }
}

const setUpdatedFolderId = (payload) => {
    return {
        type: ActionNames.SET_UPDATED_FOLDER_ID,
        payload
    }
}

const setUpdatedFolderName = (payload) => {
    return {
        type: ActionNames.SET_SHOW_UPDATED_FOLDER_NAME,
        payload
    }
}

const setShowNewFolderNameWindow = (payload) => {
    return {
        type: ActionNames.SET_SHOW_NEW_FOLDER_NAME_WINDOW,
        payload
    }
}

const setUpdatedFolder = (payload) => {
    return {
        type: ActionNames.SET_UPDATED_FOLDER,
        payload
    }
}

const setShowUpdateFolderNameErrorWindow = (payload) => {
    return {
        type: ActionNames.SET_SHOW_UPDATE_FOLDER_NAME_ERROR_WINDOW,
        payload
    }
}

const setShowReceiversWindow = (payload) => {
    return {
        type: ActionNames.SET_SHOW_RECEIVERS_WINDOW,
        payload
    }
}

const setShowReceiversErrorWindow = (payload) => {
    return {
        type: ActionNames.SET_SHOW_RECEIVERS_ERROR_WINDOW,
        payload
    }
}

const setAddReceiver = (payload) => {
    return {
        type: ActionNames.SET_ADD_RECEIVER,
        payload
    }
}

const setEditReceiver = (payload) => {
    return {
        type: ActionNames.SET_EDIT_RECEIVER,
        payload
    }
}

const setEditReceiverFileId = (payload) => {
    return {
        type: ActionNames.SET_EDIT_RECEIVER_FILE_ID,
        payload
    }
}

const setShowHomeSuccessWindow = (payload) => {
    return {
        type: ActionNames.SET_SHOW_HOME_SUCCESS_WINDOW,
        payload
    }
}

const setChangeSortType = (payload) => {
    return {
        type: ActionNames.SET_CHANGE_SORT_TYPE,
        payload
    }
}

const setChangeFileNameSortType = (payload) => {
    return {
        type: ActionNames.SET_CHANGE_FILE_NAME_SORT_TYPE,
        payload
    }
}

const setChangeRecipientSortType = (payload) => {
    return {
        type: ActionNames.SET_CHANGE_RECIPIENT_SORT_TYPE,
        payload
    }
}

const setChangeDateSentSortType = (payload) => {
    return {
        type: ActionNames.SET_CHANGE_DATE_SENT_SORT_TYPE,
        payload
    }
}

const setChangeUserNameSortType = (payload) => {
    return {
        type: ActionNames.SET_CHANGE_USER_NAME_SORT_TYPE,
        payload
    }
}

const setChangeUserTypeSortType = (payload) => {
    return {
        type: ActionNames.SET_CHANGE_USER_TYPE_SORT_TYPE,
        payload
    }
}

const setChangeUsersSortField = (payload) => {
    return {
        type: ActionNames.SET_CHANGE_USERS_SORT_FIELD,
        payload
    }
}

const setHistorySortFieldAndType = (payload) => {
    return {
        type: ActionNames.SET_CHANGE_HISTORY_SORT_FIELD_AND_TYPE,
        payload
    }
}

const setHistoryselectedFilterType = (payload) => {
    return {
        type: ActionNames.SET_CHANGE_HISTORY_USER_NAME_FILTER_TYPE,
        payload
    }
}

const setHistoryFilterCriteriaValue = (payload) => {
    return {
        type: ActionNames.SET_CHANGE_HISTORY_FILTER_CRITERIA_VALUE,
        payload
    }
}

const setHistorySelectedSortField = (payload) => {
    return {
        type: ActionNames.SET_CHANGE_HISTORY_SELECTED_SORT_TYPE,
        payload
    }
}

const setHistorySelectedSortType = (payload) => {
    return {
        type: ActionNames.SET_CHANGE_HISTORY_SELECTED_SORT_TYPE,
        payload
    }
}

const setHistorySelectedFilterField = (payload) => {
    return {
        type: ActionNames.SET_CHANGE_HISTORY_SELECTED_FILTER_FIELD,
        payload
    }
}

const setUploadProgressBarStatus = (payload) => {
    return {
        type: ActionNames.SET_UPLOAD_PROGRESS_BAR_STAUTS,
        payload
    }
}
const setShowUploadProgress = (payload) => {
    return {
        type: ActionNames.SET_SHOW_UPLOAD_PROGRESS,
        payload
    }
}

export const Actions = {
    setAuthUserName,
    setAuthPwd,
    setCurrentUser,
    setIsSuccessAuth,
    setAuthConfirmPwd,
    setActiveMenuOption,
    setShowNewPwdWindow,
    setIsSuccessPwdChange,
    setNewPwd,
    setNewConfirmPwd,
    setOldPwd,
    setFolders,
    setFoldersNames,
    setNewFolderName,
    setCreationParentFolderName,
    setUploadParentFolderName,
    setUploadFile,
    setUsers,
    setAction,
    setParam,
    setSelectedUser,
    setSelectedLink,
    setShowEditWindow,
    setIsAdmin,
    setEditUserName,
    setEditPwd,
    setIsSuccessEdit,
    setSelectedForEditUser,
    setShownFolders,
    setEditIsAdmin,
    setActivePageNumber,
    setPages,
    setShowCreateFolderErrorWindow,
    setShowUploadFileErrorWindow,
    setShowGetFileLinkErrorWindow,
    setShowRemoveFolderErrorWindow,
    setShowRemoveFileErrorWindow,
    setShowRemoveUserErrorWindow,
    setShowReactivateLinkErrorWindow,
    setHomeComponentErrorMessage,
    setIsLoading,
    setIsAuthLoading,
    setUploadFileReceiverEmail,
    setShowCreateWindow,
    setCreationIsAdmin,
    setCreationUserName,
    setCreationPwd,
    setIsSuccessCreation,
    setCreateUserName,
    setCreatePwd,
    setCreateIsAdmin,
    setUserForAction,
    setShowActionErrorWindow,
    setUpdatedFolderId,
    setUpdatedFolderName,
    setShowNewFolderNameWindow,
    setUpdatedFolder,
    setShowUpdateFolderNameErrorWindow,
    setShowReceiversWindow,
    setShowReceiversErrorWindow,
    setAddReceiver,
    setEditReceiver,
    setEditReceiverFileId,
    setShowHomeSuccessWindow,
    setChangeSortType,
    setChangeFileNameSortType,
    setChangeRecipientSortType,
    setChangeDateSentSortType,
    setChangeUserNameSortType,
    setChangeUserTypeSortType,
    setChangeUsersSortField,
    setHistorySortFieldAndType,
    setHistoryselectedFilterType,
    setHistoryFilterCriteriaValue,
    setHistorySelectedSortField,
    setHistorySelectedSortType,
    setHistorySelectedFilterField,
    setUploadProgressBarStatus,
    setShowUploadProgress
}